import React, { Component } from 'react'
import PropType from 'prop-types'
import styled, { css } from 'styled-components'
import { ContainerQuery } from 'react-container-query'
import LowerThirdInstaWeather from './components/LowerThirdInstaWeather'
import ResizeDetector from '../../../../../../components/ResizeDetector'

const Wrapper = styled(React.forwardRef(({ realSize, ...props }, ref) => <ResizeDetector ref={ref} {...props} />))`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.realSize &&
    css`
      height: auto !important;
    `};
`

const ImageBg = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  position: absolute;
  top: -25px;
  left: -25px;
  bottom: -25px;
  right: -25px;
  filter: blur(15px);
  opacity: 0.8;
`

const Image = styled(React.forwardRef(({ realSize, ...props }, ref) => <img alt="" ref={ref} {...props} />))`
  display: block;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.3);
  ${(props) =>
    (props.realSize &&
      css`
        width: 100%;
        height: auto;
        position: relative;
      `) ||
    css`
      position: absolute;
      left: -10000%;
      right: -10000%;
      top: -10000%;
      bottom: -10000%;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    `};
`

const StyledLowerThirdInstaWeather = styled(LowerThirdInstaWeather)`
  position: absolute;
  top: ${(props) => props.bottom - 48}px;
  left: ${(props) => props.left}px;
  right: ${(props) => props.right}px;
  height: 48px;
  box-sizing: border-box;
`

class DisplayImageInstaWeather extends Component {
  constructor() {
    super()
    this.imageEl = React.createRef()
    this.wrapperEl = React.createRef()
    this.state = {
      lowerThirdBottom: 0,
      lowerThirdLeft: 0,
      lowerThirdRight: 0,
    }
  }

  setLowerThirdSize = () => {
    const imageEl = this.imageEl.current
    const wrapperEl = this.wrapperEl.current
    if (imageEl === null || wrapperEl === null) return
    const imageRect = imageEl.getBoundingClientRect()
    const wrapperClientRect = wrapperEl.el.getBoundingClientRect()
    this.setState({
      lowerThirdBottom: imageRect.bottom - wrapperClientRect.top,
      lowerThirdLeft: imageRect.left - wrapperClientRect.left,
      lowerThirdRight: imageRect.left - wrapperClientRect.left,
    })
  }

  render() {
    const { image, hashtag, ...props } = this.props
    return (
      <ContainerQuery query={{ realSize: { maxWidth: 400 } }}>
        {({ realSize }) => (
          <Wrapper onResize={this.setLowerThirdSize} realSize={realSize} ref={this.wrapperEl} {...props}>
            <ImageBg image={image} />
            <Image src={image} ref={this.imageEl} realSize={realSize} onLoad={this.setLowerThirdSize} />
            <StyledLowerThirdInstaWeather
              bottom={this.state.lowerThirdBottom}
              left={this.state.lowerThirdLeft}
              right={this.state.lowerThirdRight}
            >
              {hashtag}
            </StyledLowerThirdInstaWeather>
          </Wrapper>
        )}
      </ContainerQuery>
    )
  }
}

DisplayImageInstaWeather.defaultProps = {
  hashtag: '#',
}

DisplayImageInstaWeather.propTypes = {
  image: PropType.string,
}

export default DisplayImageInstaWeather
