import React from 'react'
import {
  ViewRiseSet0CurveWrapper,
  ViewRiseSet0Icon,
  ViewRiseSet0IconWrapper,
  ViewRiseSet0Path,
  ViewRiseSet0PathWrapper,
  ViewRiseSet0StyledTextName,
  ViewRiseSet0StyledTextTime,
  ViewRiseSet0timeToCoordinates,
  ViewRiseSet0Wrapper,
} from '.'
import TextSkeleton from '../../../../../../../../components/Text/views/TextSkeleton'
import styled from 'styled-components'
import { skeletonFade } from '../../../../../../../../modules/mixins'

const StyledViewRiseSet0Icon = styled(ViewRiseSet0Icon)`
  background-color: ${(props) => props.theme.color.skeletonScreen};
  ${skeletonFade};
`
const StyledViewRiseSet0Path = styled(ViewRiseSet0Path)`
  border-color: ${(props) => props.theme.color.skeletonScreen};
  ${skeletonFade};
`

const StyledViewRiseSet0StyledTextTime = styled(ViewRiseSet0StyledTextTime)``
const StyledViewRiseSet0StyledTextName = styled(ViewRiseSet0StyledTextName)`
  width: 100%;
  margin-top: 0.2em;
`

const [top, left] = ViewRiseSet0timeToCoordinates(1, 1, 2)

const ViewRiseSetSkeleton = ({ ...props }) => (
  <ViewRiseSet0Wrapper {...props}>
    <StyledViewRiseSet0StyledTextTime as={TextSkeleton} lastWidth="37px" />
    <ViewRiseSet0CurveWrapper>
      <ViewRiseSet0PathWrapper>
        <StyledViewRiseSet0Path />
      </ViewRiseSet0PathWrapper>
      <ViewRiseSet0IconWrapper>
        <StyledViewRiseSet0Icon
          style={{
            top: `calc((${top} * 50%) + 50%)`,
            left: `calc((${left} * 50%) + 50%)`,
          }}
        />
      </ViewRiseSet0IconWrapper>
    </ViewRiseSet0CurveWrapper>
    <StyledViewRiseSet0StyledTextTime as={TextSkeleton} lastWidth="37px" />
    <StyledViewRiseSet0StyledTextName as={TextSkeleton} lastWidth="60px" align="center" />
  </ViewRiseSet0Wrapper>
)

export default ViewRiseSetSkeleton
