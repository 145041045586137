import React from 'react'
import Forecast from '../..'
import PropTypes from 'prop-types'
import PreviewSymbols from '../../../PreviewSymbols'
import translate from '../../../../../../modules/translate'
import ContainerArticle from '../../../../../../components/ContainerArticle'

const ForecastPreview = ({ data, ...props }) => {
  const textBlocks = [
    {
      text: data.description,
    },
  ]
  return (
    <Forecast
      subtitle={translate('weather_preview_header')}
      title={data.title}
      textBlocks={textBlocks}
      elementEnd={
        <ContainerArticle>
          <PreviewSymbols days={data.days} />
        </ContainerArticle>
      }
      {...props}
    />
  )
}

ForecastPreview.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    days: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          name: PropTypes.string.isRequired,
          description: PropTypes.string,
        }),
        minTemp: PropTypes.string,
        maxTemp: PropTypes.string,
      }),
    ).isRequired,
  }),
}

export default ForecastPreview
