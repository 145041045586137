import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Text from '../../../../../../../../components/Text'
import translate from '../../../../../../../../modules/translate'

const Wrapper = styled.div``

const Range = styled(Text)`
  line-height: ${(props) => props.theme.lineHeight.title};
  color: ${(props) => props.theme.color.textSecondary};
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }

  strong {
    color: ${(props) => props.theme.color.textPrimary};
  }
`

const TextTemperatureRange = ({ min, max, ...props }) => {
  return (
    <Wrapper {...props}>
      <Range>
        {max !== undefined && translate('max')} <strong>{max}</strong>
      </Range>
      <Range>
        {min !== undefined && translate('min')} <strong>{min}</strong>
      </Range>
    </Wrapper>
  )
}

TextTemperatureRange.propTypes = {
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
}

export default TextTemperatureRange

export { Wrapper as TextTemperatureRange0Wrapper }
