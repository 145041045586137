import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Text from '../../../../../../../../components/Text'

const Wrapper = styled(Text)`
  font-size: 57px;
`

const TextTemperature = ({ temperature, ...props }) => {
  return <Wrapper {...props}>{temperature && temperature + '°C'}</Wrapper>
}

TextTemperature.propTypes = {
  temperature: PropTypes.string.isRequired,
}

export default TextTemperature
