import React, { Component } from 'react'
import ReactSelect, { components } from 'react-select'
import PropTypes from 'prop-types'
import translate from '../../modules/translate'
import theme from '../../config/themes'
import isStolapp from '../../modules/is-stolapp'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    '&:hover': { borderColor: state.isFocused ? 'transparent' : 'transparent' },
    boxShadow: 'none',
    borderColor: state.isFocused ? 'transparent' : 'transparent',
    borderRadius: '0px',
  }),
  menu: (provided, state) => ({
    ...provided,
    borderColor: 'transparent',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  container: (provided, state) => ({
    ...provided,
    fontSize: theme.default.fontSize.text,
    fontFamily: theme.default.font.primary,
    borderBottom: state.isFocused
      ? '2px solid ' + theme.default.color.stol
      : '2px solid ' + theme.default.color.textTertiary,
    borderRadius: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: theme.default.fontSize.text,
    fontFamily: theme.default.font.primary,
    color: state.isSelected ? theme.default.color.stol : theme.default.color.text,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.default.color.backgroundBorder,
      color: theme.default.color.stol,
    },
    backgroundColor: state.isSelected ? theme.default.color.textTertiary : 'white',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition }
  },
}

class Select extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentValue: this.props.value,
    }
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }
  shouldComponentUpdate(nextProps) {
    if (this.state.currentValue !== nextProps.value) {
      this.setState({
        currentValue: nextProps.value,
      })
      return true
    } else {
      return true
    }
  }
  onChangeHandler(selected) {
    this.setState({
      currentValue: selected,
    })
    this.props.myOnChange(selected)
    this.props.onChange(selected)
  }
  render() {
    const { placeholder, values, value, noResultsText, noOptionsMessage, append, isSearchable, ...props } = this.props

    const Placeholder = ({ children, ...props }) => {
      return (
        <components.Placeholder {...props}>
          {children} {append}
        </components.Placeholder>
      )
    }

    return (
      <ReactSelect
        {...props}
        noOptionsMessage={() => noOptionsMessage}
        styles={customStyles}
        onChange={this.onChangeHandler}
        value={this.state.currentValue}
        components={{ Placeholder }}
        placeholder={placeholder}
        options={values}
        noResultsText={noResultsText}
        isSearchable={isSearchable ? isSearchable : !isStolapp()}
      />
    )
  }
}

Select.propTypes = {
  ...ReactSelect.propTypes,
  isSearchable: PropTypes.bool,
}

Select.defaultProps = {
  ...ReactSelect.defaultProps,
  values: [{}],
  placeholder: translate('select_default_selection'),
  myOnChange: () => {},
  onChange: () => {},
  noResultsText: translate('no_result_found'),
  noOptionsMessage: translate('select_no_options'),
} // id specifies the select. if there is only 1 select on the page you do not have to define an id.

export default Select
export { Select as StyledSelect0Select }
