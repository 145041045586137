import React, { Component } from 'react'
import TextSkeleton from '../../../../../../../../../../components/Text/views/TextSkeleton'
import { withTheme } from 'styled-components'

class TextTemperatureSkeleton extends Component {
  render = () => {
    const { ...props } = this.props
    let fontSize = '57px'
    let lastWidth = '130px'

    if (props.theme.mode.includes('mobile')) {
      fontSize = '41px'
      lastWidth = '95px'
    }
    return <TextSkeleton fontSize={fontSize} lastWidth={lastWidth} {...props} />
  }
}

export default withTheme(TextTemperatureSkeleton)
