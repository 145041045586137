import React from 'react'
import {
  Temperature0ContainerIcon,
  Temperature0StyledIconWeather,
  Temperature0StyledTextTemperature,
  Temperature0StyledTextTemperatureRange,
  Temperature0Wrapper,
} from './'
import { skeletonFade } from '../../../../../../modules/mixins'
import styled from 'styled-components'
import TextTemperatureSkeletonRange from './components/TextTemperatureRange/views/TextTemperaturRangeSkeleton'
import TextTemperatureSkeleton from './components/TextTemperature/views/TextTemperatureSkeleton'

const StyledContainerTemperature0StyledIconWeather = styled(Temperature0StyledIconWeather)`
  background: radial-gradient(
    closest-side,
    ${props => props.theme.color.skeletonScreen} 0,
    ${props => props.theme.color.skeletonScreen} 75%,
    transparent 75%,
    transparent 100%
  );
  padding-top: 100%;
  ${skeletonFade};
`
const StyledTextTemperatureSkeleton = styled(Temperature0StyledTextTemperature)``
const StyledTextTemperatureRangeSkeleton = styled(Temperature0StyledTextTemperatureRange)``

const SkeletonTemperature = ({ ...props }) => (
  <Temperature0Wrapper {...props}>
    <Temperature0ContainerIcon>
      <StyledContainerTemperature0StyledIconWeather />
    </Temperature0ContainerIcon>
    <StyledTextTemperatureSkeleton as={TextTemperatureSkeleton} />
    <StyledTextTemperatureRangeSkeleton as={TextTemperatureSkeletonRange} />
  </Temperature0Wrapper>
)

export default SkeletonTemperature
