import React from 'react'
import styled from 'styled-components'
import TextTemperature from './components/TextTemperature'
import TextTemperatureRange from './components/TextTemperatureRange'
import styledContainerQuery from 'styled-container-query'
import IconWeather from '../../../IconWeather'

const StyledTextTemperature = styled(TextTemperature)`
  margin: 0 auto;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  @media (max-width: ${props => props.theme.tablet.breakPoint + 'px'}) {
    font-size: 4.5em;
    margin-top: 0.4em;
    margin-bottom: 0.4em;
  }
  @media (max-width: ${props => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 4em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }
`
const StyledIconWeather = styled(IconWeather)`
  width: 140px;
`
const ContainerIcon = styled.div`
  display: none;
`
const StyledTextTemperatureRange = styled(TextTemperatureRange)`
  padding-left: 1.5em;
`
const Wrapper = styledContainerQuery.div`
  display: flex;
  align-items: center;
  
  &:container(max-width: 400px){
    ${StyledIconWeather} {
      width: 115px;
    }
  }
  
  &:container(max-width: 320px){
    ${StyledIconWeather} {
      width: 90px;
    }
  } 
`

const Temperature = ({ weatherCode, description, temperature, min, max, showMinMax, ...props }) => {
  return (
    <Wrapper {...props}>
      <ContainerIcon>
        <StyledIconWeather name={weatherCode} description={description} square />
      </ContainerIcon>
      <StyledTextTemperature temperature={temperature} />
      {showMinMax && <StyledTextTemperatureRange min={min} max={max} />}
    </Wrapper>
  )
}

Temperature.propTypes = {
  ...IconWeather.propTypes,
  ...TextTemperature.propTypes,
  ...TextTemperatureRange.propTypes,
}
Temperature.defautProps = {
  showMinMax: false,
}

export default Temperature

export {
  Wrapper as Temperature0Wrapper,
  ContainerIcon as Temperature0ContainerIcon,
  StyledIconWeather as Temperature0StyledIconWeather,
  StyledTextTemperature as Temperature0StyledTextTemperature,
  StyledTextTemperatureRange as Temperature0StyledTextTemperatureRange,
}
