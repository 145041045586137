import React, { Component } from 'react'
//import Text from '../../../Other/components/Text'
import styled from 'styled-components'
//import translate from '../../../../modules/translate'
import ReactJwPlayer from 'react-jw-player'
import optionsJwPlayer from '../../../../modules/options-jwplayer'
import isServerSide from '../../../../modules/is-server-side'
//import Link from '../../../Other/components/Link'
//import exampleVideo from './video/example.mp4'

const Wrapper = styled.div``

/*
const DescriptionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: ${props => props.theme.sizes.gapEdge };
    padding-left: ${props => props.theme.sizes.gapEdge };
`

*/
/*
const DescriptionInnerWrapper = styled.div`
    padding-top: ${props => props.theme.sizes.gapVerticalHalf };
    padding-bottom: ${props => props.theme.sizes.gapVerticalHalf };
`
*/

/*
const StyledText = styled(Text)`
  text-align: right;
  margin-top:${props => props.theme.sizes.gapVertical };
`

*/

//const StyledImage = styled.img`
//    max-width: 100px;
//`

class VideoWeather extends Component {
  constructor(props) {
    super(props)
    this.videoRef = React.createRef()
  }
  componentDidMount() {
    if (!isServerSide()) {
      document.addEventListener('scroll', this.checkIfVideoIsInViewport)
    }
  }
  render() {
    const { data, adTargeting, ...props } = this.props
    const optionsVideoWeather = optionsJwPlayer({})
    optionsVideoWeather.customProps.related = {}
    return (
      <Wrapper ref={this.videoRef} {...props}>
        <ReactJwPlayer
          {...optionsVideoWeather}
          customProps={{ displaytitle: false, displaydescription: false }}
          playerId={'foo'}
          playlist={data}
          isAutoPlay={false}
        />
        {/*
                            <DescriptionWrapper>
                {
                data.video.poweredBy &&
                data.video.poweredBy.map(({logo, url}, index) => (
                    <DescriptionInnerWrapper>
                        <StyledText key={index+'sponsortext'}>{translate('poweredby')}</StyledText>
                        <Link to={url}><StyledImage key={index+'sponsorimage'} src={logo} /></Link>
                    </DescriptionInnerWrapper>
                ))
                }
            </DescriptionWrapper>
                */}
      </Wrapper>
    )
  }
}

export default VideoWeather
