import React, { Component } from 'react'
import { TextTemperatureRange0Wrapper } from '../..'
import TextSkeleton from '../../../../../../../../../../components/Text/views/TextSkeleton'
import { withTheme } from 'styled-components'

class TextTemperatureSkeletonRange extends Component {
  render = () => {
    const { ...props } = this.props
    let lastWidth = '75px'

    if (props.theme.mode.includes('mobile')) {
      lastWidth = '70px'
    }
    return (
      <TextTemperatureRange0Wrapper {...props}>
        <TextSkeleton lines={2} lineHeight={1.2} lastWidth={lastWidth} />
      </TextTemperatureRange0Wrapper>
    )
  }
}

export default withTheme(TextTemperatureSkeletonRange)
