import React from 'react'
import styled from 'styled-components'
import Icon from '../../../../../../../../components/Icon'
import Link from '../../../../../../../../components/Link'
import translate from '../../../../../../../../modules/translate'

const Wrapper = styled.div`
  background-color: rgba(245, 245, 245, 0.6);
  display: flex;
  justify-content: space-between;
  padding: 0.5em ${(props) => props.theme.sizes.gapCol};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: 0.5em ${(props) => props.theme.tablet.sizes.gapCol};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: 0.5em ${(props) => props.theme.mobile.sizes.gapCol};
  }
`

const Col = styled.div`
  display: flex;
  align-items: center;
`

const StyledLink = styled(Link)`
  margin-left: 0.5em;
  color: ${(props) => props.theme.color.textPrimary};
`

const StyledIcon = styled(Icon)`
  font-size: 2em;
`

const LowerThirdInstaWeather = ({ children, ...props }) => (
  <Wrapper {...props}>
    <Col>
      <StyledIcon icon="instagram" />
      <StyledLink to={translate('insta_stol_channel_link')}>{translate('insta_stol_channel')}</StyledLink>
    </Col>
    <Col>
      <StyledLink to={translate('insta_stol_hashtag_link')}>{children}</StyledLink>
    </Col>
  </Wrapper>
)

export default LowerThirdInstaWeather
