import { Component } from 'react'
import PropTypes from 'prop-types'

class Delay extends Component {
  state = {
    value: this.props.initial,
  }

  refresh = ({ ...props }) => {
    const { value, period } = { ...props }
    this.timeout = setTimeout(() => this.setState({ value }), period)
  }

  componentDidMount() {
    this.refresh(this.props)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    return this.props.children(this.state.value)
  }
}

Delay.propTypes = {
  initial: PropTypes.number,
  value: PropTypes.number.isRequired,
  period: PropTypes.number,
  children: PropTypes.func.isRequired,
}

Delay.defaultProps = {
  period: 1500,
  initial: 0,
}

export default Delay
