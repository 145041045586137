import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ViewRiseSet from './components/ViewRiseSet'

const StyledViewRiseSet = styled(ViewRiseSet)`
  width: ${props => props.theme.sizes.col6};
  @media (max-width: ${props => props.theme.tablet.breakPoint + 'px'}) {
    width: ${props => props.theme.tablet.sizes.col6};
  }
  @media (max-width: ${props => props.theme.mobile.breakPoint + 'px'}) {
    width: ${props => props.theme.mobile.sizes.col6};
  }
`

const WrapperSunMoon = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5px;

  @media (max-width: 450px) {
    justify-content: center;

    ${StyledViewRiseSet} {
      width: 65%;
    }
    #secondViewRiseSet {
      margin-top: 30px;
    }
  }
`
const Wrapper = styled.div``

const SunMoon = ({ sunRise, sunSet, moonRise, moonSet, ...props }) => {
  return (
    <Wrapper {...props}>
      <WrapperSunMoon {...props}>
        <StyledViewRiseSet
          rise={sunRise ? sunRise : '2019-04-09T06:54:00+02:00'}
          set={sunSet ? sunSet : '2019-04-09T19:54:00+02:00'}
        />
        <StyledViewRiseSet
          id="secondViewRiseSet"
          rise={moonRise ? moonRise : '2019-04-09T19:54:00+02:00'}
          set={moonSet ? moonSet : '2019-04-09T06:54:00+02:00'}
          moon
        />
      </WrapperSunMoon>
    </Wrapper>
  )
}

SunMoon.propTypes = {
  sunRise: PropTypes.string.isRequired,
  sunSet: PropTypes.string.isRequired,
  moonRise: PropTypes.string.isRequired,
  moonSet: PropTypes.string.isRequired,
}

export default SunMoon

export {
  Wrapper as SunMoon0Wrapper,
  WrapperSunMoon as SunMoon0WrapperSunMoon,
  StyledViewRiseSet as SunMoon0StyledViewRiseSet,
}
