import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ForecastPrognosis from '../../../../components/Forecast/views/ForecastPrognosis/index'
import LiveData from '../../../../components/LiveData/index'
import ForecastPreview from '../../../../components/Forecast/views/ForecastPreview/index'
import TextCopyrightWeather from '../../../../components/TextCopyrightWeather/index'
import ForecastPrognosisSkeleton from '../../../../components/Forecast/views/ForecastPrognosis/views/ForecastPrognosisSkeleton/index'
import SkeletonLiveData from '../../../../components/LiveData/skeleton'
import ErrorBoundary from '../../../../../Error/widgets/ErrorBoundary/index'
import translate from '../../../../../../modules/translate/index'
import MessageError from '../../../../../Error/screens/MessageError/index'
import ContainerAd from '../../../../../../components/ContainerAd/index'
//import ForecastAvailability from '../../../components/Forecast/views/ForecastAvailability'
import ImageArticle from '../../../../../../components/ImageArticle/index'
import VideoWeather from '../../../../components/VideoWeather/index'
import Text from '../../../../../../components/Text'

const Wrapper = styled.div``

const StyledContainerAd = styled(ContainerAd)`
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const StyledText = styled(Text)`
  font-size: ${(props) => props.theme.default.fontSize.title2};
  margin: ${(props) => props.theme.default.sizes.gapVertical} ${(props) => props.theme.default.sizes.gapEdge} 0;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title2};
    margin: ${(props) => props.theme.tablet.sizes.gapVertical} ${(props) => props.theme.tablet.sizes.gapEdge} 0;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title2};
    margin: ${(props) => props.theme.mobile.sizes.gapVertical} ${(props) => props.theme.mobile.sizes.gapEdge} 0;
  }
  text-align: center;
`

const Prognosis = ({ loading, failed, data, adTargeting, ...props }) => {
  const errorOptions = {
    component: translate('weather_prognosis_error_today'),
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'Prognosis screen display',
  }
  const errorOptionsTomorrow = {
    component: undefined,
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'Prognosis screen display',
  }

  return (
    <Wrapper {...props}>
      <ErrorBoundary error={failed} {...errorOptions}>
        {loading ? (
          <SkeletonLiveData />
        ) : data ? (
          <LiveData data={data.live} instagram={data.instagram} />
        ) : (
          <MessageError error={failed} logInfo={translate('weather_error_block_livedata')} {...errorOptions} />
        )}
      </ErrorBoundary>
      <ErrorBoundary error={failed} {...errorOptions}>
        {loading ? (
          <ForecastPrognosisSkeleton />
        ) : data ? (
          <ForecastPrognosis data={data.today} isTomorrow={false} weatherType="forecast" />
        ) : (
          <MessageError logInfo={translate('weather_error_block_weather_today')} {...errorOptions} />
        )}
      </ErrorBoundary>
      <StyledContainerAd adIds={['S7']} />
      <ErrorBoundary error={failed} {...errorOptions}>
        {loading ? (
          <Fragment />
        ) : data && data.tomorrow ? (
          <ForecastPrognosis data={data.tomorrow} isTomorrow weatherType="forecast" />
        ) : (
          <StyledText>{translate('weather_forecast_no_data_tomorrow')}</StyledText>
        )}
      </ErrorBoundary>
      <ContainerAd adIds={['S8']} />
      <ErrorBoundary error={failed} {...errorOptions}>
        {data && data.tomorrow && data.tomorrow.video && <VideoWeather data={data.tomorrow.video} />}
      </ErrorBoundary>
      <ErrorBoundary error={failed} {...errorOptions}>
        {data && data.tomorrow && data.tomorrow.video && (
          <ImageArticle image={data.tomorrow.video.video.image} video={data.tomorrow.video.video} url="#" />
        )}
      </ErrorBoundary>
      <ErrorBoundary error={failed} {...errorOptions}>
        {loading ? (
          <Fragment />
        ) : data ? (
          <ForecastPreview data={data.preview} />
        ) : (
          <MessageError logInfo={translate('weather_error_block_4_day_forecast')} {...errorOptions} />
        )}
      </ErrorBoundary>
      <TextCopyrightWeather />
    </Wrapper>
  )
}

Prognosis.propTypes = {
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  today: ForecastPrognosis.propTypes.data,
  tomorrow: ForecastPrognosis.propTypes.data,
  preview: PropTypes.oneOfType([PropTypes.shape(ForecastPreview.propTypes), PropTypes.shape({})]),
}

Prognosis.defaultProps = {
  loading: false,
  failed: false,
  today: {},
  tomorrow: {},
  preview: {},
}

export default Prognosis
