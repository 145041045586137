import React from 'react'
import Forecast from '../..'
import PropTypes from 'prop-types'
import subtitleParser from '../../modules/subtitle-parser'
import MapWeather from '../../../MapWeather'
import translate from '../../../../../../modules/translate'

const ForecastPrognosis = ({
  data,
  isTomorrow,
  weatherType,
  ...props
}) => {
  let furtherWeather = data && data.furtherWeather
  let condition = data && data.condition
  let temperatures = data && data.temperatures
  let date = data && data.date
  let title = data && data.title
  let mapSymbols = data && data.mapSymbols
  const hasData = condition || furtherWeather || temperatures
  let textBlocks = []
  if (hasData) {
    textBlocks = [
      {
        title: translate('weather_prognosis'),
        text: furtherWeather,
      },
      {
        title: translate('weather_conditions'),
        text: condition,
      },
      {
        title: translate('weather_temperatures'),
        text: temperatures,
      },
    ]
  }

  const subtitle = subtitleParser(date, isTomorrow, hasData, weatherType)

  return (
    <Forecast
      subtitle={subtitle}
      title={title}
      textBlocks={textBlocks}
      {...hasData && { elementBelowTitle: <MapWeather data={mapSymbols} /> }}
      {...props}
    />
  )
}

ForecastPrognosis.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      furtherWeather: PropTypes.string,
      date: PropTypes.string,
      title: PropTypes.string,
      condition: PropTypes.string,
      temperatures: PropTypes.string,
      weather: PropTypes.string,
      mapSymbols: MapWeather.propTypes.data,
    }),
    PropTypes.shape({}),
  ]),
  isTomorrow: PropTypes.bool,
  weatherType: PropTypes.string,
  elementBelowTitle: Forecast.propTypes.elementBelowTitle,
  elementEnd: Forecast.propTypes.elementEnd,
}

ForecastPrognosis.defaultProps = {
  data: {},
}

export default ForecastPrognosis
