import React, { Component } from 'react'
import styled from 'styled-components'
import Temperature from './components/Temperature'
import SunMoon from './components/SunMoon'
import bottomLine from '../../../../modules/mixins/bottom-line'
import MonitoringData from './components/MonitoringData'
import RowLocation from './components/RowLocation'
import ImageInstaWeather from './components/ImageInstaWeather'
import { clientCoordinates, isLocationInSouthTyrol } from '../../../../modules/location'
import translate from '../../../../modules/translate'

const geolib = require('geolib')

const StyledImageInstaWeather = styled(ImageInstaWeather)`
  width: ${(props) => props.theme.sizes.col6};
  order: 1;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col6};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col6};
  }
`

const Wrapper = styled.div`
  width: ${(props) => props.theme.sizes.col12};
  display: flex;
  background-color: ${(props) => props.theme.color.background};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-direction: column;
    ${StyledImageInstaWeather} {
      width: 100%;
      height: 400px;
      order: 3;
    }
  }
`

const SubWrapper = styled.div`
  margin-left: ${(props) => props.theme.sizes.gapCol};
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  position: relative;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.tablet.sizes.gapCol};
    margin-right: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.mobile.sizes.gapCol};
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const DataWrapper = styled.div`
  flex: 1;
  padding: ${(props) => props.theme.sizes.gapColHalf} 0;
  order: 2;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: ${(props) => props.theme.tablet.sizes.gapColHalf} 0;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: ${(props) => props.theme.mobile.sizes.gapColHalf} 0;
  }
`

const FirstWrapper = styled(SubWrapper)`
  padding-bottom: ${(props) => props.theme.sizes.gapColHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-bottom: ${(props) => props.theme.tablet.sizes.gapColHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-bottom: ${(props) => props.theme.mobile.sizes.gapColHalf};
  }
  ${bottomLine};
`

class LiveData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedStations: [],
      selectedStationOption: undefined,
      temperature: '',
    }
    this.onUpdateStation = this.onUpdateStation.bind(this)
    this.findNearest = this.findNearest.bind(this)
  }
  onUpdateStation(selectedStation) {
    if (selectedStation.value === 0) {
      this.findNearest()
    } else {
      this.props.data &&
        this.props.data.stationsData.map((station) => {
          if (selectedStation.value === station.id) {
            this.setState({
              selectedStations: [station],
              selectedStationOption: this.returnWeatherOption(selectedStation),
              temperature: station.temperature,
            })
          }
          return null
        })
    }
  }
  componentDidMount() {
    this.findNearest()
  }
  returnWeatherOption(station) {
    if (station && station.gps) {
      return { value: station.id, label: station.name + ' (' + station.gps.alt + ' m)' }
    }
  }
  findNearest() {
    clientCoordinates()
      .then((coordinates) => {
        if (isLocationInSouthTyrol(coordinates.latitude, coordinates.longitude)) {
          let justLatLongValues = { latitude: coordinates.latitude, longitude: coordinates.longitude }
          let minDistanceFinderHelper
          let nearestStation
          this.props.data &&
            this.props.data.stationsData.map((station) => {
              if (station.category === 1 || station.category === 3) {
                let latLongStation = {
                  latitude: parseFloat(station.gps.lat.replace(',', '.')),
                  longitude: parseFloat(station.gps.lng.replace(',', '.')),
                }
                if (minDistanceFinderHelper === undefined) {
                  minDistanceFinderHelper = geolib && geolib.getDistance(justLatLongValues, latLongStation)
                }
                if (geolib && minDistanceFinderHelper > geolib.getDistance(justLatLongValues, latLongStation)) {
                  minDistanceFinderHelper = geolib && geolib.getDistance(justLatLongValues, latLongStation)
                  nearestStation = station
                }
              }
              return null
            })
          if (nearestStation !== undefined) {
            this.setState({
              selectedStations: [nearestStation],
              selectedStationOption: this.returnWeatherOption(nearestStation),
              temperature: nearestStation.temperature,
            })
          }
        } else {
          this.setDefaultStation()
        }
      })
      .catch(() => {
        this.setDefaultStation()
      })
  }
  setDefaultStation() {
    const defaultStation = this.props.data.stationsData.find((station) => station.default && station.default === true)
    if (defaultStation !== undefined) {
      this.setState({
        selectedStations: [defaultStation],
        selectedStationOption: this.returnWeatherOption(defaultStation),
        temperature: defaultStation.temperature,
      })
    }
  }

  render() {
    const { onChange, data, instagram, ...props } = this.props
    let selectableStations = []
    data &&
      data.stationsData.map((station) => {
        //setting default station
        if (station.default && station.default === true) {
          if (this.state.selectedStations.length <= 0 && this.state.selectedStationOption === undefined) {
            this.setState({
              selectedStations: [station],
              selectedStationOption: this.returnWeatherOption(station),
              temperature: station.temperature,
            })
          }
        }
        if (station.category === 1 || station.category === 3) {
          selectableStations.push(this.returnWeatherOption(station))
        }
        /* TODO add Pegelastationen
      if(station.category === 2){
        selectableStations.push({value: station.id, label: station.name.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ').replace('Bei', 'bei') + ' ('+station.gps.alt+ ' m) - Pegelstation'})
        helperVarToFindNearestWaterLevelStation[station.id] = {latitude: parseFloat(station.gps.lat.replace(",", ".")), longitude: parseFloat(station.gps.lng.replace(",", "."))}
      }
      */
        return null
      })
    let alphabeticSortedStations = selectableStations.sort((previousValue, nextValue) => {
      if (previousValue.label < nextValue.label) {
        return -1
      }
      if (previousValue.label > nextValue.label) {
        return 1
      }
      return 0
    })
    alphabeticSortedStations.unshift({ value: 0, label: translate('nearme_title') })
    return (
      <Wrapper {...props}>
        <DataWrapper>
          <FirstWrapper>
            <RowLocation
              values={alphabeticSortedStations}
              value={this.state.selectedStationOption}
              onChange={this.onUpdateStation}
              placeholder={'select_weather_station_selection'}
            />
            <Temperature
              temperature={
                this.state.temperature && this.state.temperature.toLocaleString('de-DE', { maximumFractionDigits: 2 })
              }
              min={this.props.data && this.props.data.min.toString()}
              max={this.props.data && this.props.data.max.toString()}
            />
            <SunMoon sunRise={data.sunRise} sunSet={data.sunSet} moonRise={data.moonRise} moonSet={data.moonSet} />
          </FirstWrapper>
          <SubWrapper>
            <MonitoringData monitoringData={this.state.selectedStations} />
          </SubWrapper>
        </DataWrapper>
        <StyledImageInstaWeather image={instagram && instagram.images[0]} hashtag={instagram && instagram.tag} />
      </Wrapper>
    )
  }
}

LiveData.propTypes = {
  ...RowLocation.propTypes,
}

export default LiveData

export {
  Wrapper as LiveData0Wrapper,
  FirstWrapper as LiveData0FirstWrapper,
  SubWrapper as LiveData0SubWrapper,
  DataWrapper as LiveData0DataWrapper,
  StyledImageInstaWeather as LiveDataStyledImageInstaWeather,
}
