import React from 'react'
import { SunMoon0StyledViewRiseSet, SunMoon0Wrapper, SunMoon0WrapperSunMoon } from './'
import styledContainerQuery from 'styled-container-query'
import ViewRiseSetSkeleton from './components/ViewRiseSet/skeleton'
import styled from 'styled-components'

const StyledSunMoon0StyledViewRiseSet = styled(SunMoon0StyledViewRiseSet)``

const StyledSunMoon0WrapperSunMoon = styledContainerQuery(SunMoon0WrapperSunMoon)`
  &:container(max-width: 410px){
    justify-content: center;
  
    ${StyledSunMoon0StyledViewRiseSet} {
      width: 65%;
    }
    ${StyledSunMoon0StyledViewRiseSet}:nth-child(2) {
      margin-top: 30px;
    }
  }
  
  &:container(max-width: 410px){
    justify-content: center;
  
    ${StyledSunMoon0StyledViewRiseSet} {
      width: 65%;
    }
    ${StyledSunMoon0StyledViewRiseSet}:nth-child(2) {
      margin-top: 30px;
    }
  }
`

const SkeletonSunMoon = ({ ...props }) => (
  <SunMoon0Wrapper {...props}>
    <StyledSunMoon0WrapperSunMoon>
      <StyledSunMoon0StyledViewRiseSet as={ViewRiseSetSkeleton} />
      <StyledSunMoon0StyledViewRiseSet as={ViewRiseSetSkeleton} />
    </StyledSunMoon0WrapperSunMoon>
  </SunMoon0Wrapper>
)

export default SkeletonSunMoon
