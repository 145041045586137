import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import styledContainerQuery from 'styled-container-query'
import { ContainerQuery } from 'react-container-query'
import TableStation from './components/TableStation'

const StyledTableStation = styled(TableStation)``
const WeatherStations = styledContainerQuery.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  ${StyledTableStation}:nth-last-child(1):nth-child(odd) {
    width: ${props => props.theme.sizes.col12};
  }
  
  &:container(max-width: 440px){
    ${StyledTableStation} {
      width: ${props => props.theme.sizes.col12};
    }
  }
`
const Wrapper = styled.div``

const DisplayMonitoringData = ({ monitoringData, ...props }) => (
  <Wrapper {...props}>
    <ContainerQuery
      query={{
        mobile: {
          maxWidth: 440,
        },
      }}
    >
      {params => (
        <div>
          <WeatherStations>
            {
            monitoringData.map((stationData, index) => (
              <StyledTableStation
                key={index}
                stationData={stationData}
                isHeadRow={index % 2 ? params.mobile : TableStation.defaultProps.isHeadRow}
                {...{ monitoringData }}
              />
            ))}
          </WeatherStations>
        </div>
      )}
    </ContainerQuery>
  </Wrapper>
)

DisplayMonitoringData.propTypes = {
  monitoringData: PropTypes.arrayOf(PropTypes.shape(TableStation.propTypes).isRequired).isRequired,
}

export default DisplayMonitoringData
