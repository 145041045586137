import React from 'react'
//import SkeletonSelectCommune from '../../../../../Other/components/SelectCommune/skeleton'
import { SingleElementRowLocation, WrapperRowLocation } from '.'

const SkeletonRowLocation = ({ ...props }) => (
  <WrapperRowLocation {...props}>
    <SingleElementRowLocation>
      
    </SingleElementRowLocation>
    <SingleElementRowLocation />
  </WrapperRowLocation>
)

export default SkeletonRowLocation
